import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`When I started programming it never occurred to me that I’d end up bonding with a database.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sure, PostgreSQL has some neat tricks up its sleeve. And yes, working with Mongo for 4 months made me realize I never knew how good I had it with MySQL.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Hopefully this isn’t about to turn into a love letter; there is an unmistakable fondness welling up inside me as I think about all the crappy SQL clients, all the backwards ways I went about learning it, out of necessity at first, then out of curiosity, and finally it became that I couldn’t live without it, and longed for the days when upper management was feeling data hungry and I did nothing but build report all day.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s not waste any more time. I’m going to talk about 3 things:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Writing Queries at Speed vs. Writing Queries for Speed`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Group-Bys, Nested Selectors & How It Finally Started to Click`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Writing Queries at Speed, Revisited`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`I think it was 2016. I was at a diner eating latkes and arguing with an old friend about SQL, about how slow it was, and so old, saying these things confidently without ever having written a SELECT statement.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fastforward 2 years and it’s 2018. I’m moving back to Texas to work for a company that is fast-tracking me for a Junior Engineer position. I’d missed Texas. I was in school again for computer science. It was May and I was feeling hopeful.`}</MDXTag>
      <MDXTag name="p" components={components}>{`How I came to learn SQL is somewhat roundabout -- basically, I’d previously been a contractor for this same company, working remotely, with scant access to information or tools to do my job well.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Actually that’s bullshit. My job was easy, I was just nosy. So when my`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-04-19-a-year-of-sql-in-retrospect",
  "date": "2019-04-19",
  "title": "A Year of SQL in Retrospect",
  "image": "2019-04-19-a-year-of-sql-in-retrospect.EXT",
  "sharpImage": true,
  "imgOverlay": "rgba(255, 255, 255, 1.0)",
  "tags": ["sql", "data-visualization", "databases"],
  "excerpt": "Excerpt here",
  "published": false
};
      